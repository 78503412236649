import { useAuthStore } from '~/stores/auth';
import { REDIRECTION_ROUTES_QUERIES } from '~/constants/redirectionRoutes';
import { SESSION_STORAGE } from '~/constants/storedPropertiesNames';

export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.server) {
    return;
  }

  const { getUserData } = useAuthStore();

  if (!getUserData.loggedIn && REDIRECTION_ROUTES_QUERIES.cashier in to.query) {
    sessionStorage.setItem(SESSION_STORAGE.oldPath, encodeURIComponent(to.fullPath));

    return navigateTo({ path: useLocalePrefixForHref('/login') });
  }

  if (to.query[REDIRECTION_ROUTES_QUERIES.cashier] === null) {

    const query = { ...to.query };

    delete query[REDIRECTION_ROUTES_QUERIES.cashier];

    return navigateTo({
       path: to.path,
       query,
    });
  }
});