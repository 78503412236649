export const LOCAL_STORAGE = {
  referrerAz: 'j-referrer-az',
  pageQueryString: 'j-page-query-string',
  cookiePolicy: 'j-cookie-policy',
  codeValidityInMilliseconds: 'j-code-validity-in-milliseconds',
  notifications: 'j-notifications',
  bonusActivationCache: 'j-bonus-activation-cache',
};

export const SESSION_STORAGE = {
  openCashModal: 'j-open-cash-modal',
  oldPath: 'j-old-path',
};

export const COOKIE = {
  activeLanguage: 'activeLanguage',
  userPlayerId: 'userPlayerId',
  jSessionIdFE: 'JSESSIONID_FE',
};
